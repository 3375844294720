import {
  advancedSettingsKeys,
  EDITOR_CONTENT_LITERAL,
  templateRelatedKeys
} from './template_related_keys'
import Utils from '../utils'
import { type AxiosResponse } from 'axios'
import AdvancedSettingsData from './advanced_settings_data'
import { toHtmlEntities, fromHtmlEntities } from '@avvoka/shared'
import { Delta, isInsert } from '@avvoka/editor'
import { Optional } from '@avvoka/shared'
import AvvParser from '../parser'
import { disableButton, enableButton, isElementDisabled } from '../dom_utils'

function decodeEditorDelta(delta: Delta) {
  for (const op of delta.ops) {
    if (isInsert(op) && typeof op.insert === 'string') {
      op.insert = fromHtmlEntities(op.insert)
      op.insert = AvvParser.decode(op.insert)
    }
  }

  return delta
}

function encodeEditorDelta(delta: Delta) {
  const docxHtmlEntities = {
    60: '&lt;',
    62: '&gt;',
    34: '&quot;',
    38: '&amp;'
  }

  for (const op of delta.ops) {
    if (isInsert(op) && typeof op.insert === 'string') {
      op.insert = toHtmlEntities(op.insert, docxHtmlEntities, false)
      op.insert = AvvParser.encode(op.insert)
    }
  }

  return delta
}

export default class AutosaveUtils {
  static loadErrorMessage = localizeText('autosave.errors.load')
  static saveErrorMessage = localizeText('autosave.errors.load')
  static destroyErrorMessage = localizeText('autosave.errors.destroy')
  static initialState = ''

  public static initializeShortcuts() {
    document.addEventListener('keydown', (e) => {
      const isCtrlOrCmdS = (e.ctrlKey || e.metaKey) && e.key === 's'
      if (isCtrlOrCmdS) {
        // Prevent the Save dialog to open
        e.preventDefault()
        void AutosaveUtils.save()
      }
    })
  }

  public static shouldDoRequest() {
    const changeWasMade = AutosaveUtils.initialState !== AutosaveUtils.data()
    return changeWasMade && !window.avvLoading
  }

  public static initializeState() {
    AutosaveUtils.initialState = AutosaveUtils.data()
  }

  public static async load() {
    const response = await Utils.axios.get(AutosaveUtils.URL)
    AutosaveUtils.handleResponse(
      response,
      AutosaveUtils.loadErrorMessage,
      AutosaveUtils.applyData
    )
  }

  public static async save(): Promise<void> | never {
    if (!AutosaveUtils.shouldDoRequest()) return
    AutosaveUtils.setSavingUI()
    AutosaveUtils.disableSaveButton()
    AutosaveUtils.disablePublishButton()
    const response = await Utils.axios.post(AutosaveUtils.URL, {
      template_autosave: AutosaveUtils.data()
    })
    AutosaveUtils.handleResponse(
      response,
      AutosaveUtils.saveErrorMessage,
      AutosaveUtils.afterSave
    )
  }

  public static beaconSave() {
    if (AvvStore.state.has_autosave) return
    if (!AutosaveUtils.shouldDoRequest()) return
    const data = new FormData()
    data.append('authenticity_token', Utils.CSRFToken)
    data.append('template_autosave', AutosaveUtils.data())
    window.navigator.sendBeacon(AutosaveUtils.URL, data)
  }

  public static async destroy() {
    const response = await Utils.axios.delete(AutosaveUtils.URL)
    AutosaveUtils.handleResponse(response, AutosaveUtils.destroyErrorMessage)
  }

  public static afterSave() {
    AutosaveUtils.initializeState()
    AutosaveUtils.showAutosaveUI()
    AutosaveUtils.setSavedUI()
    AutosaveUtils.enablePublishButton()
    AutosaveUtils.enableSaveButton()
    AvvStore.state.has_autosave = true
  }

  private static handleResponse(
    response: AxiosResponse,
    errorMessage: string,
    callback?: (response: AxiosResponse) => void
  ) {
    const validStatuses = [200, 204]
    if (!validStatuses.includes(response.status))
      avv_dialog({ snackMessage: errorMessage, snackStyle: 'error' })
    else if (callback) {
      callback(response)
    }
  }

  private static applyData(response: AxiosResponse) {
    let { data } = response
    if (!data) return
    Object.keys(data as Record<string, unknown>).forEach((key) => {
      if (key === EDITOR_CONTENT_LITERAL)
        EditorFactory.main.load(
          new Delta(decodeEditorDelta(data[key] as Delta))
        )
      if (advancedSettingsKeys.includes(key))
        AdvancedSettingsData.write(key, data[key] as string | boolean)
      AvvStore.state[key] = AvvParser.decodeAnything(data[key], ['cond'])
    })
  }

  public static data() {
    const slicedData = templateRelatedKeys.reduce((acc, key) => {
      acc[key] = AutosaveUtils.getData(key)
      return acc
    }, {})

    return JSON.stringify(slicedData)
  }

  private static getData(key: string) {
    if (key === EDITOR_CONTENT_LITERAL)
      return EditorFactory.mainOptional
        .andThen((editor) => Optional.of(encodeEditorDelta(editor.getDelta())))
        .getOr(new Delta())
    if (advancedSettingsKeys.includes(key))
      return AdvancedSettingsData.read(key)
    return AvvParser.encodeAnything<
      string | number | Record<string, unknown> | unknown[]
    >(AvvStore.state[key], ['cond'])
  }

  private static showAutosaveUI() {
    const elementsToShow = Array.from(
      document.querySelectorAll<HTMLElement>('.autosave-show-ui')
    )
    const showElement = (element: HTMLElement) =>
      element.classList.remove('hidden')
    elementsToShow.forEach(showElement)
  }

  public static setSavingUI() {
    const element = AutosaveUtils.showAndGetDisplayElement()
    if (!element) return
    element.textContent = localizeText('autosave.saving')
  }

  public static setSavedUI() {
    const element = AutosaveUtils.showAndGetDisplayElement()
    if (!element) return
    element.textContent = localizeText('autosave.autosaved')
  }

  public static setLoadingUI() {
    const element = AutosaveUtils.showAndGetDisplayElement()
    if (!element) return
    element.textContent = localizeText('autosave.loading')
  }

  public static setLoadedUI() {
    const element = AutosaveUtils.showAndGetDisplayElement()
    if (!element) return
    element.textContent = localizeText('autosave.loaded')
  }

  private static showAndGetDisplayElement() {
    const element = document.querySelector<HTMLElement>(
      'span.autosave-show-ui'
    )!
    if (!element) return
    element.classList.remove('hidden')
    return element
  }

  private static get URL() {
    return `/template_versions/${AvvStore.state.template_version_id}/template_autosave`
  }

  public static saveTemplate() {
    const saveButton = document.getElementById(
      'save-template'
    ) as HTMLButtonElement
    if (!saveButton) {
      throw new Error('Save button not found')
    }

    if (isElementDisabled(saveButton)) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'disabled'
          ) {
            if (!saveButton.disabled) {
              saveButton.click()
              observer.disconnect()
              break
            }
          }
        }
      })

      observer.observe(saveButton, { attributes: true })
    } else {
      saveButton.click()
    }
  }

  public static disableSaveButton() {
    const saveButton = document.getElementById(
      'save-template'
    ) as HTMLButtonElement
    if (saveButton) {
      disableButton(saveButton)
    }
  }

  public static enableSaveButton() {
    const saveButton = document.getElementById(
      'save-template'
    ) as HTMLButtonElement
    if (saveButton) {
      enableButton(saveButton)
    }
  }

  public static disablePublishButton() {
    const publishButton = document.getElementById(
      'publish-template'
    ) as HTMLButtonElement
    if (publishButton) {
      disableButton(publishButton)
    }
  }

  public static enablePublishButton() {
    const publishButton = document.getElementById(
      'publish-template'
    ) as HTMLButtonElement
    if (publishButton) {
      enableButton(publishButton)
    }
  }
}
